<template>
	<v-dialog
		v-model="dialog"
		width="800"
		:fullscreen="$vuetify.breakpoint.xsOnly"
		persistent
		style="background-color: blue; border-radius: 30px !important">
		<v-form v-model="valid">
			<v-card flat style="overflow: hidden">
				<v-card-title class="secondary py-1" style="height: 40px">
					<span class="dialog-title">{{ method === "add" ? $t("create") : $t("edit") }} {{ $t("organization") }}</span>
					<v-spacer/>
					<v-icon small color="primary" @click="$emit('false')">mdi-window-close</v-icon>
				</v-card-title>
				<v-card-text color="primary" class="mt-5">
					<span class="d-flex align-end justify-end px-3"><span style="color: red">{{ $t("requiredFields") }}</span></span>
				</v-card-text>

				<v-row class="px-6">
					<v-col cols="12">
						<v-row dense cols="12" class="mb-2">
							<v-col cols="12" lg="6" md="6" sm="12">
								<validation-provider
									name="Organization name"
									rules="required"
									v-slot="{ errors }">
									<v-text-field
										v-model="localSelectedMedicalInstitution.institutionName"
										:label="$t('organization-name')"
										prepend-inner-icon="mdi-office-building mr-1"
										dense
										background-color="#E8E8E8"
										rounded
										outlined
										style="border-radius: 12px !important; height: 40px"
										:error-messages="errors">
									</v-text-field>
								</validation-provider>
							</v-col>
							<v-col cols="12" lg="6" md="6" sm="12">
								<validation-provider
									name="Contact person"
									rules="required"
									v-slot="{ errors }">
									<v-text-field
										v-model="localSelectedMedicalInstitution.contactPerson"
										:label="$t('contact-person')"
										prepend-inner-icon="mdi-account mr-1"
										dense
										background-color="#E8E8E8"
										rounded
										outlined
										style="border-radius: 12px !important; height: 40px"
										:error-messages="errors">
									</v-text-field>
								</validation-provider>
							</v-col>
						</v-row>
						<v-row dense cols="12" class="mb-2">
							<v-col cols="12" lg="6" md="6" sm="12">
								<validation-provider
									ref="phone"
									:name="$t('phone')"
									:arg="{ localSelectedMedicalInstitution }"
									rules="required|phone"
									v-slot="{ errors }">
									<v-text-field
										v-model="localSelectedMedicalInstitution.contactPhone"
										validate-on-blur
										:label="$t('phone-number')"
										prepend-inner-icon="mdi-cellphone"
										dense
										background-color="#E8E8E8"
										rounded
										outlined
										style="border-radius: 12px !important; height: 40px"
										:error-messages="(phoneError = errors)">
									</v-text-field>
								</validation-provider>
							</v-col>
							<v-col cols="12" lg="6" md="6" sm="12">
								<validation-provider
									ref="email"
									:name="$t('email')"
									rules="required|email"
									v-slot="{ errors }">
									<v-text-field
										v-model="localSelectedMedicalInstitution.contactEmail"
										:label="$t('contact-email')"
										prepend-inner-icon="mdi-email mr-1"
										dense
										background-color="#E8E8E8"
										rounded
										outlined
										style="border-radius: 12px !important; height: 40px"
										:error-messages="(emailError = errors)">
									</v-text-field>
								</validation-provider>
							</v-col>
						</v-row>
						<v-row class="mb-2" dense>
							<v-col cols="12" lg="12" md="12" sm="12">
								<validation-provider
									name="Address"
									rules="required"
									v-slot="{ errors }">
									<v-text-field
										ref="address"
										v-model="localSelectedMedicalInstitution.address"
										validate-on-blur
										:label="$t('a-s-p-c-c-c')"
										prepend-inner-icon="mdi-map-marker mr-1"
										dense
										background-color="#E8E8E8"
										rounded
										outlined
										style="border-radius: 12px !important; height: 40px"
										:error-messages="errors">
									</v-text-field>
								</validation-provider>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12" lg="6" md="6" sm="12">
								<validation-provider
									name="City"
									rules="required"
									v-slot="{ errors }">
										<v-text-field
										v-model="localSelectedMedicalInstitution.city"
										:label="$t('city')"
										prepend-inner-icon="mdi-map-marker mr-4"
										dense
										background-color="#E8E8E8"
										rounded
										outlined
										style="border-radius: 12px !important; height: 40px"
										:error-messages="errors">
									</v-text-field>
								</validation-provider>
							</v-col>
							<v-col cols="12" lg="6" md="6" sm="12">
								<validation-provider
									name="Zip Code"
									rules="required"
									v-slot="{ errors }"
									>
									<v-text-field
										v-model="localSelectedMedicalInstitution.zip"
										:label="$t('zip-code')"
										prepend-inner-icon="mdi-map-marker mr-4"
										dense
										background-color="#E8E8E8"
										rounded
										outlined
										style="border-radius: 12px !important; height: 40px"
										:error-messages="errors">
									</v-text-field>
								</validation-provider>
							</v-col>
						</v-row>
						<v-row dense class="pt-1">
							<v-col cols="12" lg="6" md="6" sm="12">
								<validation-provider
								name="State"
								rules="required"
								v-slot="{ errors }"
								>
								<v-text-field
								v-model="localSelectedMedicalInstitution.state"
								:label="$t('state')"
								prepend-inner-icon="mdi-map-marker mr-4"
								dense
								background-color="#E8E8E8"
								rounded
								outlined
								style="border-radius: 12px !important; height: 40px"
								:error-messages="errors"
								></v-text-field>
								</validation-provider>
							</v-col>
							<v-col cols="12" lg="6" md="6" sm="12">
								<validation-provider
									name="Country"
									rules="required"
									v-slot="{ errors }">
									<v-text-field
										v-model="localSelectedMedicalInstitution.country"
										:label="$t('country')"
										prepend-inner-icon="mdi-map-marker mr-4"
										dense
										background-color="#E8E8E8"
										rounded
										outlined
										style="border-radius: 12px !important; height: 40px"
										:error-messages="errors">
									</v-text-field>
								</validation-provider>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12" lg="12" md="12" sm="12">
								<validation-provider
									name="Description"
									rules="required"
									v-slot="{ errors }">
									<v-textarea
										v-model="localSelectedMedicalInstitution.description"
										:label="$t('description')"
										prepend-inner-icon="mdi-card-text mr-1"
										dense
										background-color="#E8E8E8"
										rounded
										outlined
										style="border-radius: 12px !important"
										:error-messages="errors">
									</v-textarea>
								</validation-provider>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-card-actions class="px-6">
					<Alert
						class="mt-4"
						v-if="showErrors"
						:title="title"
						:type="alertType">
					</Alert>
					<v-spacer/>
					<div class="my-3">
						<v-btn class="table-cancel-button" @click="$emit('false')">
						<span color="black--text">{{ $t("cancel") }}</span>
						</v-btn>
						<v-btn
							class="table-create-button mx-2"
							:loading="loading"
							:disabled="areFieldsEmpty"
							@click="manageMedicalInstitution">
							<span>{{ $t("save") }}</span>
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>
<script>

import Alert from '@/views/Client/components/AlertDialog.vue';
import { AsYouType } from 'libphonenumber-js';

export default {
	components: {
		Alert,
	},
	props: ['dialog', 'selectedMedicalInstitution', 'method'],
	data () {
		return {
			loading: false,
			responseStatus: false,
			responseTitle: '',
			responseColor: '',
			title: 'Something went wrong',
			alertType: false,
			showErrors: false,
			validationErrors: [],
			valid: false,
			localSelectedMedicalInstitution: this.selectedMedicalInstitution,
		};
	},
	computed: {
		areFieldsEmpty () {
			if (this.method === 'edit') {
				return false;
			} else {
				for (const field in this.localSelectedMedicalInstitution) {
					if (!this.localSelectedMedicalInstitution[field]) {
						return true;
					}
				}
				return false;
			}
		},
	},
	async mounted () {
		if (this.method === 'add') {
			this.localSelectedMedicalInstitution.contactPhone = '+1';
		}
	},
	methods: {
		async locationDetails () {
			try {
				if (this.localSelectedMedicalInstitution.address !== '' && this.localSelectedMedicalInstitution.city !== '' && this.localSelectedMedicalInstitution.zip !== '' && this.localSelectedMedicalInstitution.state !== '' && this.localSelectedMedicalInstitution.country !== '') {
					var addressForValidation;
					if (this.method === 'add') {
						addressForValidation = this.localSelectedMedicalInstitution.address + ', ' + this.localSelectedMedicalInstitution.city + ', ' + this.localSelectedMedicalInstitution.state + ' ' + this.localSelectedMedicalInstitution.zip + ', ' + this.localSelectedMedicalInstitution.country;
					} else {
						addressForValidation = this.localSelectedMedicalInstitution.address;
					}
					const geoLocation = await this.$store.dispatch(
						'googleAPI/getGeoLocation',
						addressForValidation,
					);
					if (!geoLocation) {
						this.validationErrors.push('address');
						this.localSelectedMedicalInstitution = {
							lat: 0.01,
							lng: 0.01,
						};
						return false;
					}
					if (geoLocation.address === undefined || geoLocation.address == null) {
						this.validationErrors.push('address');
						this.localSelectedMedicalInstitution = {
							lat: 0.01,
							lng: 0.01,
						};
						return false;
					}

					const addressComponents = geoLocation.address;

					this.localSelectedMedicalInstitution.lat = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
					this.localSelectedMedicalInstitution.lng = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
					this.localSelectedMedicalInstitution.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.userDetails.address : geoLocation.display_name;
					this.localSelectedMedicalInstitution.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
					this.localSelectedMedicalInstitution.state = addressComponents.state || 'N/A';
					this.localSelectedMedicalInstitution.country = addressComponents.country || 'N/A';
					this.localSelectedMedicalInstitution.zip = addressComponents.postcode || 'N/A';
					return true;
				} else {
					this.localSelectedMedicalInstitution = {
						lat: 0.01,
						lng: 0.01,
					};
					return false;
				}
			} catch (error) {
				return false;
			}
		},

		async checkValid () {
			this.validationErrors = [];

			try {
				if (this.emailError.length > 0) {
					this.validationErrors.push('email');
				}
				if (this.localSelectedMedicalInstitution.address === '') {
					this.validationErrors.push('address');
				}
				const waitAddressValidation = await this.locationDetails();
				if (!waitAddressValidation) {
					this.validationErrors.push('address');
				}
				this.selectedMedicalInstitution.contactPhone = new AsYouType().input(this.selectedMedicalInstitution.contactPhone);

				if (this.validationErrors.length > 0) {
					return false;
				} else {
					return true;
				}
			} catch (error) {
				return false;
			}
		},

		async createMedicalInstitution (body) {
			await this.$store.dispatch('medicalInstitutions/createMedicalInstitution', body)
				.then((res) => {
					if (res) {
						this.$emit('success', res);
					}
				});
		},
		async editMedicalInstitution (body) {
			body.id = this.localSelectedMedicalInstitution.id;
			await this.$store.dispatch('medicalInstitutions/editMedicalInstitution', body)
				.then((res) => {
					if (res) {
						this.$emit('success', res);
					}
				});
		},
		async manageMedicalInstitution () {
			const valid = await this.checkValid();
			if (!valid) {
				this.title = 'Provided data is not valid: ' + this.validationErrors.toString();
				this.alertType = 'error';
				this.showErrors = true;
			}
			if (valid) {
				this.validationErrors = [];
				this.showErrors = false;
				const body = {
					institutionName: this.localSelectedMedicalInstitution.institutionName,
					description: this.localSelectedMedicalInstitution.description,
					contactPerson: this.localSelectedMedicalInstitution.contactPerson,
					contactPhone: this.localSelectedMedicalInstitution.contactPhone,
					contactEmail: this.localSelectedMedicalInstitution.contactEmail,
					address: this.localSelectedMedicalInstitution.address,
					zip: this.localSelectedMedicalInstitution.zip,
					city: this.localSelectedMedicalInstitution.city,
					state: this.localSelectedMedicalInstitution.state,
					country: this.localSelectedMedicalInstitution.country,
					addressLatitude: this.localSelectedMedicalInstitution.lat,
					addressLongitude: this.localSelectedMedicalInstitution.lng,
				};
				if (this.method === 'add') {
					this.createMedicalInstitution(body);
				} else {
					this.editMedicalInstitution(body);
				}
			}
		},
	},
};
</script>
