<template>
  <div>
    <v-row class="mx-2">
      <v-col cols="12">
        <v-card flat style="border-radius: 12px" elevation="6">
          <v-card-title>
            <v-row>
              <v-col class="d-flex align-center justify-start" cols="8"
                ><h5 class="d-flex align-left">
                  {{ $t("organizations") }}
                </h5>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search-organization')"
                  single-line
                  hide-details
                  filled
                  dense
                  background-color="#E8E8E8"
                  rounded
                  class="table-search-button"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
			dense
			:loading="loading"
			:no-data-text="$t('no-data-available')"
			:loading-text="$t('loading')"
			:headers="headers"
			:headers-length="headers.length"
			:search="search"
			:items="medicalInstitutions"
			:items-per-page="-1"
			sort-by="id"
			item-key="id"
			fixed-header
			style="cursor: pointer"
			@click:row="medicalInstitutionDetails"
			:height="testHeight"
      :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
          >
            <template v-slot:[`footer.prepend`]>
              <v-btn
                v-if="roleName === 'SYSTEM_ADMIN'"
                class="table-create-button"
                label="Test"
                @click="addMedicalInstitution"
              >
				<img class="pt-1" height="20px" width="20px" :src="organizations_white" style="margin-right:3%; margin-bottom: 5%;"/>
                <span style="text-transform: none;">{{ $t("addMedicalInstitutionTooltip") }}</span>
              </v-btn>
            </template>
            <template v-slot:[`item.statusShortDescription`]="{ item }">
              <img :src="patient_icon(item.status)" height="24px" />
            </template>
          </v-data-table>
        </v-card>
        <AddMedicalInstitutionDialog
          v-if="dialog"
          :dialog="dialog"
          :method="method"
          :selectedMedicalInstitution="selectedMedicalInstitution"
          @false="closeDialog"
          @success="success"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import AddMedicalInstitutionDialog from '../dialogs/AddMedicalInstitutionDialog.vue';
import { organizationsWhite } from '@/assets';

export default {
	components: {
		AddMedicalInstitutionDialog,
	},
	data: () => ({
		dialog: false,
		loading: true,
		medicalInstitutions: [],
		search: '',
		selectedMedicalInstitution: {},
		method: '',
		headerClass: 'black_inner--text !important',
		organizations_white: organizationsWhite,
	}),
	computed: {
		...mapGetters({ roleName: 'authentication/getRole', delay: 'authentication/getDelay' }),
		...mapState({
			pageSize: (state) => state.index.pageSize,
			medicalInstitutionsFromRepo: (state) =>
				state.medicalInstitutions.medicalInstitutions,
		}),
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return window.innerHeight - 320;
			case 'sm':
				return window.innerHeight - 300;
			case 'md':
				return window.innerHeight - 300;
			case 'lg':
				return window.innerHeight - 280;
			case 'xl':
				return window.innerHeight - 280;
			default:
				return 0;
			}
		},
		headers () {
			var headers = [];
			headers = [
				{
					text: this.$t('header-institute-name'),
					value: 'institutionName',
					class: this.headerClass,
				},
				{
					text: this.$t('header-contact-person'),
					value: 'contactPerson',
					class: this.headerClass,
				},
				{
					text: this.$t('header-contact-phone'),
					value: 'contactPhone',
					class: this.headerClass,
				},
				{
					text: this.$t('header-contact-email'),
					value: 'contactEmail',
					class: this.headerClass,
				},
			];
			return headers;
		},
		height () {
			return window.innerHeight - 250;
		},
	},
	async mounted () {
		await this.getMedicalInstitutions();
	},
	methods: {
		addMedicalInstitution () {
			this.method = 'add';
			this.selectedMedicalInstitution = {};
			this.dialog = true;
		},
		editMedicalInstitution (item) {
			this.method = 'edit';
			this.selectedMedicalInstitution = item;
			this.dialog = true;
		},
		async getMedicalInstitutions () {
			await this.$store
				.dispatch('medicalInstitutions/getMedicalInstitutions')
				.then(() => {
					this.medicalInstitutions = this.medicalInstitutionsFromRepo;
					this.loading = false;
				});
		},
		medicalInstitutionDetails (item) {
			this.editMedicalInstitution(item);

			// Do not delete this commented code until delete medical inst is implemented

			// this.$router.push({
			//   name: "Medical Organization Details",
			//   params: { id: `${item.id}` },
			// });
		},
		closeDialog () {
			this.dialog = false;
			this.getMedicalInstitutions();
		},
		success (res) {
			this.dialog = false;
			this.getMedicalInstitutions();
			if (res.resFlag) {
				this.$store.commit('alerts/add', {
					id: 'addedOrganization',
					type: 'success',
					color: 'main_green',
					// message: res.msg,
					message: this.$t('success'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$store.commit('alerts/add', {
					id: 'addedOrganization',
					type: 'error',
					color: 'main_red',
					// message: res.msg,
					message: this.$t('failed'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
	},
};
</script>
